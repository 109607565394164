<template>
    <div class="setting">
        <div class="box">
            <div class="felx" @click="getbeishu()">
                <div class="multiple">商品价格倍数</div>
                <div class="number">{{thisnum}}</div>
            </div>
            <Popup v-model="show" position="bottom">
                    <Picker show-toolbar title="选择倍数" :columns="List" value-key="multiples" @confirm="onConfirm" @cancel="onCancel"/>
            </Popup>
        </div>
        <div class="list-box" style="color: #ccc;text-align: center;margin-top:1rem">
            <div>更多功能正在努力开发中...</div>
            <div>敬请期待</div>
        </div>
    </div>
</template>
<script>
    import { Popup,Picker } from "vant";
    import { MultipleList,setMultiple,getMultiple } from  "@api/tasting";
    export default {
        name: "setting",
        data: function() {
            return {
                List:[],
                thisnum:'默认',
                show:false,
            };
        },
        components: {
            Popup,Picker
        },
        created: function() {
            
        }, 
        mounted: function() {
            this.getthisbeishu();
        },
        methods: {
            onCancel() {
                this.show = false;
            },
            onConfirm(e){
                //console.log(e.id);
                this.setbeishu(e);
            },
           getbeishu:function(){
               MultipleList(

               ).then(res=>{
                    console.log(res.data);
                    //this.List=res.data.data;
                    let datalist = res.data;
                    datalist.forEach(item => {
                        item.multiples=item.res;
                    });
                    this.List=datalist;
                    this.show = true;
               })
           },
           setbeishu:function(val){
               console.log(val);
               var _this = this;
               setMultiple({id:val.id}
               ).then(res=>{
                    _this.onCancel();
                    _this.thisnum = val.multiples;
                    _this.$dialog.message(res.msg);
               }).catch(res => {
                    _this.$dialog.error(res.msg);
               });
           },
           getthisbeishu:function(){
               getMultiple({

               }).then(res=>{
                    this.thisnum = res.data.number;
                    console.log(res);
               }).catch(res => {
                    console.log(res);
               });
           },
            
        },
}
</script>
<style lang="scss">
.setting{
    .box{
        .felx{
            display: flex;
            align-items: center;
            justify-content: center; 
            background: #fff;
            height: 1rem;
            padding: 0.2rem;
            border-bottom:1px solid #ccc ;
            div{
                width: 50%;
            }
            .multiple{
                text-align: left;
            }
            .number{
                text-align: right;
            }
        }
    }
}
</style>